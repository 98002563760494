import React from 'react'

const Contact = () => {
  return (
    <div name='contact' className='w-full h-screen bg-[#f6f7f9] flex justify-center items-center p-4'>
      <form method='POST' action="https://getform.io/f/c6af0f34-471b-4d28-87d5-a1a4b3233e39"
            className='flex flex-col max-w-[600px] w-full'>
        <div className='pb-8'>
          <p className='text-6xl inline border-b-4 border-teal-400 text-gray-600'>Get In Touch</p>
          <p className='text-gray-600 py-4'>Please fill out the form on this section to contact with me.</p>
        </div>
        <input className='border-gray-200 bg-[#f6f7f9] p-3 border-2' type="text" placeholder='Name' name='name'/>
        <input className='my-4 border-gray-200 bg-[#f6f7f9] p-3 border-2' type="email" placeholder='Email' name='email'/>
        <textarea className='border-gray-200 bg-[#f6f7f9] p-3 border-2' name="message" rows="10" placeholder='Message'></textarea>
        <button
          className='text-white border-2 bg-gray-800 hover:bg-white hover:border-gray-800 px-10 py-3 my-8 mx-auto flex items-center hover:text-gray-800'>Submit Message
        </button>
      </form>
    </div>
  )
}

export default Contact
