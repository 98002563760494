import React from 'react';

import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import ReactImg from '../assets/react.png';
// import Node from '../assets/node.png';
import Photoshop from '../assets/photoshop.png';
// import Boostrap from '../assets/bootstrap.png';
import Figma from '../assets/figma.png';
import XD from '../assets/xd.png';
import GitHub from '../assets/github.png';
// import GitLab from '../assets/gitlab.png';
// import Tailwind from '../assets/tailwind.png';
// import Mongo from '../assets/mongo.png';
import MySql from '../assets/mysql.png';
import Agile from '../assets/agile.png';

const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen bg-[#f6f7f9] text-gray-600'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
          <div>
              <p className='text-6xl inline border-b-4 border-teal-400 '>Skills I have</p>
              <p className='py-4'>My experience include these technologies.</p>
          </div>

          <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={HTML} alt="HTML icon" />
                  <p className='my-4'>HTML</p>
              </div>
              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={CSS} alt="HTML icon" />
                  <p className='my-4'>CSS</p>
              </div>
{/*              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={Boostrap} alt="HTML icon" />
                  <p className='my-4'>BOOTSTRAP</p>
              </div>
              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={Tailwind} alt="HTML icon" />
                  <p className='my-4'>TAILWIND</p>
              </div>*/}
              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={JavaScript} alt="HTML icon" />
                  <p className='my-4'>JAVASCRIPT</p>
              </div>
              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={Photoshop} alt="Photoshop" />
                  <p className='my-4'>PHOTOSHOP</p>
              </div>
              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={Figma} alt="HTML icon" />
                  <p className='my-4'>FIGMA</p>
              </div>
              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={XD} alt="Adobe XD" />
                  <p className='my-4'>ADOBE XD</p>
              </div>
              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={ReactImg} alt="HTML icon" />
                  <p className='my-4'>REACT</p>
              </div>
{/*              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={ReactImg} alt="HTML icon" />
                  <p className='my-4'>REACT NATIVE</p>
              </div>*/}
              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={GitHub} alt="HTML icon" />
                  <p className='my-4'>GITHUB</p>
              </div>
{/*              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={GitLab} alt="GitLab" />
                  <p className='my-4'>GITLAB</p>
              </div>*/}
              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={Agile} alt="Agile" />
                  <p className='my-4'>Agile</p>
              </div>

{/*              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={Node} alt="HTML icon" />
                  <p className='my-4'>NODE JS</p>
              </div>*/}
{/*              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={Mongo} alt="HTML icon" />
                  <p className='my-4'>MONGO DB</p>
              </div>*/}
              <div className='shadow-md hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={MySql} alt="MySql icon" />
                  <p className='my-4'>MySql</p>
              </div>
          </div>
      </div>
    </div>
  );
};

export default Skills;
