import React from 'react';

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#f6f7f9] text-gray-600'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
          <div className='sm:text-right pb-8 pl-4'>
            <p className='text-6xl inline border-b-4 border-teal-400'>
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
          <div className='sm:text-right text-4xl font-bold'>
            <p>I'm UI/UX Developer.</p>
          </div>
          <div>
            <p>A professional and enthusiastic UI/UX developer who enjoys being part of, and leading, a successful and
              productive team. An ability to grasp new ideas and concepts quickly, as well as develop innovative and
              creative solutions to problems. Ability to work independently and demonstrate high levels of motivation to
              meet tight deadlines. Capable of performing effectively even under significant pressure.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
