import React from 'react';
// import { HiArrowNarrowRight } from 'react-icons/hi';

const Home = () => {
  return (
    <div name='home' className='w-full h-screen bg-[#f6f7f9]'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <h1 className='text-4xl sm:text-7xl font-bold text-[#4bafc2]'>About Me</h1>
        <h5 className='text-1xl sm:text-2xl font-bold text-gray-800'>
          I'm Dijup Tuladhar, UI/UX Designer <br/>from Kathmandu, Nepal
        </h5>
        <p className='text-gray-700 py-4 max-w-[700px]'>
          An enthusiastic and professional UI/UX Developer who enjoys being part of, as well as leading, a successful and productive team.
        </p>
{/*        <div>
          <button className='text-gray-600 group border-2 px-6 py-3 my-2 flex items-center hover:bg-teal-600 hover:border-teal-400 hover:text-white'>
            View Work
            <span className='group-hover:rotate-90 duration-300'>
              <HiArrowNarrowRight className='ml-3 ' />
            </span>
          </button>
        </div>*/}
      </div>
    </div>
  );
};

export default Home;
